<script setup>
import { ref, onMounted, watch, reactive, onBeforeUnmount } from "vue";
import { useUserStore } from "../../stores/user";
import { useRoute } from "vue-router";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { useToast } from "vue-toastification";
import {
  ticketStatusColor,
  ticketPriorityColor,
  ticketCategoryColor,
} from "../../utils/supportTicketUtils";
import { shortDateWithHourMinTz } from "../../utils/dateUtils";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import gravatarUrl from "gravatar-url";
import useSupportTickets from "../../hooks/useSupportTickets";
import { CustomImage, fileHandler, convertUrlToLink } from "../../utils/tiptapUtils";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import Link from "@tiptap/extension-link";
const {
  updatePage,
  supportTicketsLoading,
  supportTicket,
  fetchSupportTicket,
  fetchSupportTicketComments,
  deleteSupportTicket,
  uploadImage,
  upload,
  uploadInProgress,
  addComment,
  changeCategory,
  changeStatus,
  changePriority,
  deleteSupportTicketComment,
  fetchSupportTicketAssignments,
  assignSupportTicket,
  supportTicketAssignments,
  unassignSupportTicket,
  supportTicketUsers,
  fetchSupportTicketUsers,
  ticketCategories,
  ticketStatuses,
  ticketPriorities,
} = useSupportTickets();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();
const newStatus = ref("awaiting_response");
const newCategory = ref(null);
const newPriority = ref(null);
const newCommentData = reactive({
  commentContent: "<p>I’m running Tiptap with Vue.js. 🎉</p>",
  commentEditor: null,
});
const placeholder = Placeholder.configure({
  placeholder: "Click here and begin typing a new comment...",
});
const localState = reactive({
  page: parseInt(route.query.page) || 1,
  searchText: "",
  assignmentSearch: "",
  filters: [],
  sort: "id",
  isAssignmentDialogOpen: false,
});

// Initialize supportTicketComments as a reactive object with data as an array
const supportTicketComments = reactive({
  data: [],
  total_records: 0,
  total_pages: 0,
});

// Pagination parameters for comments
const commentsPagination = reactive({
  page: 1,
  perPage: 10,
  totalPages: 1,
  sort: "-created_at",
});

const infiniteScrollObserver = ref(null);
let observer = null;

const loadComments = async () => {
  try {
    const response = await fetchSupportTicketComments(route.params.id, {
      page: commentsPagination.page,
      per_page: commentsPagination.perPage,
      sort: commentsPagination.sort,
    });

    if (commentsPagination.page === 1) {
      // First page, replace existing comments
      supportTicketComments.data = response.data;
    } else {
      // Subsequent pages, append new comments
      supportTicketComments.data.push(...response.data);
    }

    commentsPagination.totalPages = response.total_pages;
    supportTicketComments.total_records = response.total_records;
  } catch (error) {
    toast.error("Failed to load comments.");
  }
};

onMounted(async () => {
  newCommentData.commentEditor = new Editor({
    extensions: [
      StarterKit,
      CustomImage,
      placeholder,
      fileHandler(upload),
      Link.configure({ openOnClick: false }),
    ], // Use CustomImage instead of Image
    onUpdate: ({ editor }) => {
      newCommentData.commentContent = editor.getHTML();
    },
  });
  await loadData();

  convertUrlToLink(newCommentData.commentEditor);

  // Set up the IntersectionObserver for infinite scrolling
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  observer = new IntersectionObserver(async (entries) => {
    if (entries[0].isIntersecting) {
      if (commentsPagination.page < commentsPagination.totalPages) {
        commentsPagination.page += 1;
        await loadComments();
      }
    }
  }, options);

  if (infiniteScrollObserver.value) {
    observer.observe(infiniteScrollObserver.value);
  }
});

// Disconnect the observer when the component is unmounted
onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});

watch(
  () => localState.page,
  async (newVal, _) => {
    await loadData();
  }
);

watch(
  () => localState.assignmentSearch,
  async (newVal, _) => {
    await searchUsers(newVal);
  }
);

async function searchUsers(search) {
  localState.filters = localState.filters.filter((filter) => filter.column !== "name");
  localState.filters.push({
    condition: "like",
    column: "name",
    value: search,
  });
  debouncedSupportTicketUsers();
}

const debouncedSupportTicketUsers = debounce(async () => {
  await fetchSupportTicketUsers(localState);
}, 500);

const loadData = async () => {
  try {
    const ticketData = await fetchSupportTicket(route.params.id);
    if (
      userStore.user.tenant_id != ticketData.tenant_id &&
      !userStore.hasSupportTicketRole("superadmin")
    ) {
      router.push("/403");
      return;
    }

    setPageTitle(`${ticketData.title}`);
    newCategory.value = ticketData.category;
    newPriority.value = ticketData.priority;
    await loadComments();
    await fetchSupportTicketAssignments(route.params.id);
  } catch (error) {
    console.error(error);
    toast.error("An error occurred.");
  }
};

const assignTicket = async (ticketId, userId) => {
  await assignSupportTicket(ticketId, userId);
  localState.isAssignmentDialogOpen = false;
};

const modifyHtmlForNewTab = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  doc.querySelectorAll("img").forEach((img) => {
    img.setAttribute("onclick", `window.open('${img.src}', '_blank')`);
    img.style.cursor = "pointer"; // Optional, to show it's clickable
  });

  return doc.body.innerHTML;
};

const isEmpty = () => !newCommentData.commentEditor.state.doc.textContent.trim().length;

const handleAddComment = async () => {
  try {
    if (userStore.isSupportTicketAdmin()) {
      newStatus.value = newStatus.value || "awaiting_response";
    } else {
      newStatus.value = "open";
    }
    await addComment(route.params.id, newCommentData.commentContent, newStatus.value);

    // Reset pagination and comments
    commentsPagination.page = 1;
    commentsPagination.totalPages = 1;
    supportTicketComments.data = [];

    await loadComments();

    newCommentData.commentContent = "";
    newCommentData.commentEditor.commands.clearContent(true);
  } catch (error) {
    console.error(error);
    if (isEmpty()) {
      toast.error("Comment cannot be empty.");
      return;
    } else {
      toast.error(error.response?.data?.data?.join(". ") || "An error occurred.");
    }
  }
};
</script>

<template>
  <v-container fluid class="px-4" v-if="!supportTicketsLoading && supportTicket">
    <v-row>
      <v-col cols="12 p-0">
        <v-alert
          color="warning"
          v-if="supportTicket.category == 'triage' && userStore.user.support_ticket_admin"
          class="mb-4"
        >
          Ticket is currently in triage. Please assign it a proper category.
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9">
        <v-card flat color="transparent" elevation="0">
          <div class="d-flex justify-space-between align-center card-content">
            <div class="card-title">
              <v-card-title class="custom-title">{{ pageTitle }}</v-card-title>
              <v-card-subtitle
                >Created on {{ shortDateWithHourMinTz(supportTicket.created_at) }}.
                <v-span v-if="supportTicket.updated_at"
                  >Last updated
                  {{ shortDateWithHourMinTz(supportTicket.updated_at) }}</v-span
                ></v-card-subtitle
              >
            </div>
            <div class="pr-2 d-flex align-center controls">
              <v-select
                hide-details
                density="compact"
                v-model="newStatus"
                v-if="userStore.hasSupportTicketRole('superadmin')"
                @update:modelValue="changeStatus(supportTicket.id, newStatus)"
                label="New Status"
                :items="ticketStatuses"
                :item-title="title"
                :item-value="value"
                prepend-inner-icon="mdi-state-machine"
                class="mr-2 w-33"
              />
              <v-select
                v-if="userStore.hasSupportTicketRole('superadmin')"
                density="compact"
                hide-details
                v-model="newCategory"
                label="Category"
                @update:modelValue="changeCategory(supportTicket.id, newCategory)"
                :items="ticketCategories"
                :item-title="title"
                :item-value="value"
                prepend-inner-icon="mdi-shape-outline"
                class="mr-2 w-33"
              />
              <v-select
                density="compact"
                hide-details
                v-model="newPriority"
                @update:modelValue="changePriority(supportTicket.id, newPriority)"
                label="Priority"
                :items="ticketPriorities"
                :item-title="title"
                :item-value="value"
                prepend-inner-icon="mdi-priority-high"
                class="w-33"
              />
            </div>
          </div>
        </v-card>

        <v-card flat class="w-100 mr-md-4 mt-4">
          <v-card-title> Ticket Body</v-card-title>
          <v-card-text
            class="comment-content"
            v-html="modifyHtmlForNewTab(supportTicket.content)"
          ></v-card-text>
        </v-card>

        <v-card flat class="w-100 mr-md-4 mt-8" v-if="supportTicketComments">
          <v-card-title>New Comment</v-card-title>
          <v-card-text>
            <div class="pb-4">
              <v-row no-gutters class="mb-2.5 mt-2 d-flex justify-space-between">
                <v-col>
                  <v-btn
                    prepend-icon="mdi-image"
                    color="background"
                    class="mr-2 mt-1"
                    @click="uploadImage(newCommentData.commentEditor)"
                    >image</v-btn
                  >
                  <v-btn
                    prepend-icon="mdi-format-bold"
                    color="background"
                    @click="
                      newCommentData.commentEditor.chain().focus().toggleBold().run()
                    "
                    :disabled="
                      !newCommentData.commentEditor
                        .can()
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                    "
                    :class="{
                      'mr-2 mt-1': true,
                      'is-active': newCommentData.commentEditor.isActive('bold'),
                    }"
                  >
                    bold
                  </v-btn>
                  <v-btn
                    prepend-icon="mdi-format-italic"
                    color="background"
                    @click="
                      newCommentData.commentEditor.chain().focus().toggleItalic().run()
                    "
                    :disabled="
                      !newCommentData.commentEditor
                        .can()
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                    "
                    :class="{
                      'mr-2 mt-1': true,
                      'is-active': newCommentData.commentEditor.isActive('italic'),
                    }"
                  >
                    italic
                  </v-btn>
                  <v-btn
                    prepend-icon="mdi-format-strikethrough-variant"
                    color="background"
                    @click="
                      newCommentData.commentEditor.chain().focus().toggleStrike().run()
                    "
                    :disabled="
                      !newCommentData.commentEditor
                        .can()
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run()
                    "
                    :class="{
                      'mr-2 mt-1': true,
                      'is-active': newCommentData.commentEditor.isActive('strike'),
                    }"
                  >
                    strike
                  </v-btn>
                  <v-btn
                    prepend-icon="mdi-code-tags"
                    color="background"
                    @click="
                      newCommentData.commentEditor.chain().focus().toggleCode().run()
                    "
                    :disabled="
                      !newCommentData.commentEditor
                        .can()
                        .chain()
                        .focus()
                        .toggleCode()
                        .run()
                    "
                    :class="{
                      'mr-2 mt-1': true,
                      'is-active': newCommentData.commentEditor.isActive('code'),
                    }"
                  >
                    code
                  </v-btn>
                </v-col>
                <v-col class="d-flex" cols="12" md="3">
                  <v-select
                    hide-details
                    density="compact"
                    v-model="newStatus"
                    v-if="userStore.hasSupportTicketRole('superadmin')"
                    label="New Status"
                    :items="ticketStatuses"
                    :item-title="title"
                    :item-value="value"
                    prepend-inner-icon="mdi-state-machine"
                  />
                </v-col>
              </v-row>
              <div class="mt-6 mb-2">
                <div>
                  <v-overlay
                    :model-value="uploadInProgress"
                    class="align-center justify-center"
                    contained
                  >
                    <v-progress-circular
                      class="mt-16"
                      color="primary"
                      size="64"
                      indeterminate
                    ></v-progress-circular>
                  </v-overlay>
                </div>

                <EditorContent :editor="newCommentData.commentEditor"></EditorContent>
              </div>
              <div class="d-flex justify-end align-center mt-8">
                <div class="d-flex">
                  <v-btn @click="handleAddComment" color="primary">Add Comment</v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card flat class="w-100 mr-md-4 mt-8" v-if="supportTicketComments">
          <v-card-title
            >Latest Comments ({{
              supportTicketComments.total_records
            }}
            total)</v-card-title
          >
          <v-card-text>
            <div class="pb-4 pt-4">
              <div
                v-for="(comment, idx) in supportTicketComments.data"
                :key="comment.id"
                class="d-flex flex-wrap pr-2align-center mb-8 w-100 justify-space-between"
              >
                <div class="d-flex align-center py-2">
                  <v-avatar
                    @click="router.push('/users/' + comment.created_by_id)"
                    v-if="comment.created_by.email"
                    :image="
                      gravatarUrl(comment.created_by.email, {
                        size: 40,
                        default: 'retro',
                      })
                    "
                    size="32"
                    color="surface-variant"
                    class="cursor-pointer"
                  ></v-avatar>
                  <span
                    @click="router.push('/users/' + comment.created_by_id)"
                    class="ml-2 cursor-pointer"
                    >{{ comment.created_by.name }}</span
                  >
                  <v-chip
                    size="small"
                    color="error"
                    class="ml-2"
                    v-if="comment.created_by.support_ticket_admin"
                    >admin</v-chip
                  >
                </div>
                <div class="w-auto text-caption d-flex align-center">
                  <span class="w-auto ml-4 text-grey-darken-2">{{
                    shortDateWithHourMinTz(comment.created_at)
                  }}</span>
                  <v-chip size="x-small" v-if="idx == 0" color="yellow" class="ml-2"
                    >NEWEST COMMENT</v-chip
                  >
                  <span class="w-auto mx-2 text-grey-darken-2">|</span>
                  <router-link
                    @click.prevent="deleteSupportTicketComment(comment)"
                    to="#"
                    v-if="userStore.hasSupportTicketRole('superadmin')"
                    class="text-error"
                    >Delete</router-link
                  >
                </div>
                <div class="w-100 bg-background pa-4 d-flex rounded">
                  <span
                    class="w-100 comment-content"
                    v-html="modifyHtmlForNewTab(comment?.content)"
                  ></span>
                </div>
              </div>
              <!-- Add the sentinel div here -->
              <div ref="infiniteScrollObserver"></div>
            </div>
            <v-card
              class="mt-4"
              elevation="0"
              v-if="supportTicketComments.total_records == 0"
            >
              No comments yet.
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card flat class="w-100 mt-8 mt-md-0 mb-8">
          <v-card-title> Details</v-card-title>
          <v-card-text>
            <v-row class="py-0">
              <v-col cols="5">ID</v-col>
              <v-col> {{ supportTicket.id }} </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col cols="5">Status</v-col>
              <v-col>
                <v-chip size="small" :color="ticketStatusColor(supportTicket.status)">{{
                  supportTicket.status
                }}</v-chip>
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col cols="5">Category</v-col>
              <v-col>
                <v-chip
                  size="small"
                  :color="ticketCategoryColor(supportTicket.category)"
                  >{{ supportTicket.category }}</v-chip
                >
              </v-col>
            </v-row>
            <v-row class="py-0">
              <v-col cols="5">Priority</v-col>
              <v-col>
                <v-chip
                  size="small"
                  :color="ticketPriorityColor(supportTicket.priority)"
                  >{{ supportTicket.priority }}</v-chip
                >
              </v-col>
            </v-row>

            <v-row class="py-0">
              <v-col cols="5">Tenant</v-col>
              <v-col
                class="d-flex align-center"
                @click="router.push('/tenants/' + supportTicket.tenant.id)"
              >
                <v-avatar
                  :image="
                    gravatarUrl(supportTicket.tenant.email || 'none@example.com', {
                      size: 32,
                      default: 'retro',
                    })
                  "
                  size="28"
                  color="surface-variant"
                  class="mr-2"
                ></v-avatar>

                <span class="text-link">{{ supportTicket.tenant.name }}</span>
              </v-col>
            </v-row>

            <v-row class="py-0">
              <v-col cols="5">Created by</v-col>
              <v-col
                class="d-flex align-center"
                @click="router.push('/users/' + supportTicket.created_by_id)"
              >
                <v-avatar
                  :image="
                    gravatarUrl(supportTicket.created_by.email, {
                      size: 32,
                      default: 'retro',
                    })
                  "
                  size="28"
                  color="surface-variant"
                  class="mr-2"
                ></v-avatar>

                <span class="text-link">{{ supportTicket.created_by.name }}</span>
              </v-col>
            </v-row>

            <v-row class="py-0">
              <v-col cols="4">Created at</v-col>
              <v-col class="d-flex align-center">
                <v-chip
                  size="small"
                  :color="ticketPriorityColor(supportTicket.priority)"
                  >{{ shortDateWithHourMinTz(supportTicket.created_at) }}</v-chip
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card flat class="w-100">
          <v-card-title class="d-flex align-center justify-space-between">
            Assignments
            <v-btn
              size="sm"
              flat
              icon
              @click="localState.isAssignmentDialogOpen = true"
              class="ml-2"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            <v-dialog width="500" v-model="localState.isAssignmentDialogOpen">
              <template v-slot:default="{ isActive }">
                <v-card title="New Ticket Assignment" class="pb-2">
                  <v-card-text>
                    <v-row>
                      <v-text-field
                        v-model="localState.assignmentSearch"
                        label="Search for user"
                      ></v-text-field>
                    </v-row>
                    <div style="height: 200px" class="pb-4" v-if="supportTicketUsers">
                      <div
                        v-for="user in supportTicketUsers.data"
                        class="assignment-row my-2"
                      >
                        <span
                          class="d-flex align-center pa-2"
                          @click="assignTicket(supportTicket.id, user.id)"
                        >
                          <v-avatar
                            :image="
                              gravatarUrl(user.email, {
                                size: 32,
                                default: 'retro',
                              })
                            "
                            size="28"
                            color="surface-variant"
                            class="mr-2"
                          />{{ user.name }}</span
                        >
                      </div>
                    </div>
                    <v-row class="mb-2" v-if="!supportTicketUsers"
                      ><span class="text-center">No users found.</span></v-row
                    >
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>
          </v-card-title>
          <v-card-text>
            <v-row class="py-4 px-2">
              <span v-if="!supportTicketAssignments">No assignments yet.</span>
              <div
                v-for="assignment in supportTicketAssignments"
                class="d-flex align-center"
              >
                <div class="avatar-container">
                  <v-avatar
                    @click="router.push('/users/' + supportTicket.created_by_id)"
                    :image="
                      gravatarUrl(assignment.assigned_to.email, {
                        size: 32,
                        default: 'retro',
                      })
                    "
                    size="28"
                    color="surface-variant"
                    class="cursor-pointer"
                  ></v-avatar>
                  <!-- X button for unassignment -->
                  <div class="unassign-btn">
                    <v-btn
                      icon
                      small
                      @click="unassignSupportTicket(assignment.id, supportTicket.id)"
                    >
                      <v-icon size="12">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-tooltip activator="parent" location="top">{{
                  assignment.assigned_to.name
                }}</v-tooltip>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="d-flex justify-center" v-if="userStore.isSupportTicketAdmin">
          <v-btn
            color="#DC143C"
            class="mt-16"
            variant="outlined"
            @click="deleteSupportTicket(supportTicket)"
            >Delete Support Ticket</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.card-title {
  width: 60%;
}

.controls {
  width: 40%;
}

.custom-title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
    gap: 30px;
  }

  .controls {
    width: 100%;
  }

  .card-title {
    width: 100%;
  }

  .p-0 {
    padding: 0px;
  }
}
</style>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  p {
    font-size: 1.1rem;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  textarea {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    font-size: 1.1rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#999, 0.4);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#999, 0.4);
    margin: 2rem 0;
  }
}

/* Placeholder (on every new line) */
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #999;
  pointer-events: none;
  height: 0;
}
.ProseMirror-focused {
  outline: none;
}

.comment-content img {
  width: 40%;
  height: auto;
  padding: 1.4rem;
}

.comment-content p {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.assignment-row :hover {
  width: 100%;
  cursor: pointer;
  background-color: #efefef;
  color: #333333;
}

.avatar-container {
  position: relative;
  display: inline-block;

  // Ensure the avatar and button are aligned correctly
  .v-btn {
    height: 20px;
    width: 20px;
  }
  .unassign-btn {
    position: absolute;
    // Adjust these values as needed to position the button correctly
    top: 0;
    right: -5px;
    z-index: 1;

    // Set a specific size for the button if necessary
    width: 20px; // Example size, adjust as needed
    height: 0px; // Example size, adjust as needed

    // Additional styling to ensure the button is not distorted
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .v-icon {
      // If the icon size is still causing issues, consider adjusting it here
      font-size: 12px; // Example size, adjust as needed
    }
  }
}
</style>
